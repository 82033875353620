import { Networking } from "../../networking";
import { Response } from "../../response";
import { Report } from "../models/report_model";

export class ReportRepo {
  private networking = new Networking();
  readonly fileId: string;

  constructor(fileId: string) {
    this.fileId = fileId;
  }

  async createReport(name: string, url: string): Promise<Response> {
    let response = await this.networking.postData(
      `report/create_in/${this.fileId}`,
      { title: name, url: url },
      { "Content-Type": "application/json" }
    );

    return response;
  }

  async getReports(): Promise<Report[]> {
    let response = await this.networking.getData(
      `report/get_all/${this.fileId}`
    );

    if (response.isSuccess) {
      let data: Array<Map<string, any>> = response.data;

      let reports: Report[] = data.map((x) => Report.fromJson(x));

      return reports;
    }

    return [];
  }

  static async getReportPreview(fileId: string): Promise<Report | undefined> {
    let response = await new Networking().getData(`report/get_all/${fileId}`);

    let data = response.data;

    if (data instanceof Array && data.length !== 0) {
      return Report.fromJson(data[0]);
    }
  }
}
