import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
	Layout,
	Row,
	Col,
	Form,
	Card,
	Input,
	Typography,
	Badge,
	Image,
	Modal,
} from "antd";
import "./Url.less";
import { BackHeader, FormButton } from "../../utils/components";
import { useHistory, useLocation } from "react-router-dom";
import { RoutePath } from "../../utils/routePath";
import correctJsonFormat1 from "../../assets/faq/correct_json_format.png";
import correctJsonFormat2 from "../../assets/faq/correct_json_format_2.png";
import correctJsonFormat3 from "../../assets/faq/correct_json_format_3.png";
import wrongJsonFormat1 from "../../assets/faq/wrong_json_format.png";
import wrongJsonFormat2 from "../../assets/faq/wrong_json_format_2.png";
import { UrlRepo } from "../../services/api/repositories/url_repo";
import { v4 as uuidv4 } from "uuid";
import mixpanel from "mixpanel-browser";

const { Title } = Typography;

export const FillUrl: React.FC<any> = () => {
	let history = useHistory();

	let location = useLocation();

	let urlRepo = new UrlRepo();

	const [form] = Form.useForm();

	const [isLoading, setLoading] = useState(false);

	const [_imageSrc, setImageSrc] = useState<any>();

	const [_imageModal, setImageModal] = useState(false);

	const [_url, setUrl] = useState("");

	const [_apiKey, setApiKey] = useState(uuidv4());

	const locationState: any = location.state;

	const onFinish = async (values: any) => {
		mixpanel.track("Fill_Url_Excecute_Add_Url_Click");

		history.push({
			pathname: RoutePath.fill_param,
			state: {
				url: values.url.trim(),
				userApiId: locationState.userApiId,
				apiKey: _apiKey,
			},
		});
	};
	useEffect(() => {
		async function initState() {
			let apiResult = await urlRepo.getUrlByApiKey({
				apiKey: locationState.apiKey,
			});

			if (apiResult.isSuccess) {
				setUrl(apiResult.data[0].url);
				setApiKey(locationState.apiKey);
			}
		}

		initState();
	}, []);
	useEffect(() => form.resetFields(), [_url]);

	return (
		<>
			<div className="navbar" style={{ backgroundColor: "#fff" }}>
				<BackHeader title="Fill Url / API" />
			</div>
			<Modal
				title="JSON format Guidelines"
				centered
				visible={_imageModal}
				onOk={() => setImageModal(false)}
				onCancel={() => setImageModal(false)}
				destroyOnClose={true}
			>
				<img alt="json format instruction" src={_imageSrc} />
			</Modal>
			<Row className="url_row">
				<Col xs={24} xl={8} className="url_col" span={8} />
				<Col xs={24} xl={8} className="url_col" span={8}>
					<Card>
						<Form
							name="fill_url"
							className="url_form"
							form={form}
							initialValues={{ url: _url }}
							onFinish={onFinish}
						>
							<Form.Item
								name="url"
								label="Url/API"
								rules={[
									{
										required: true,
										message: "Please input your Url/API!",
									},
								]}
							>
								<Input placeholder="Example: https://www.yourdomain.com/getdata" />
							</Form.Item>
							<Form.Item>
								<FormButton
									title="Check"
									isLoading={isLoading}
								/>
							</Form.Item>
						</Form>
					</Card>
					<div style={{ height: "20px" }}></div>
					<Title level={5}>JSON Response format</Title>
					<Row>
						<Col
							xs={24}
							xl={12}
							className="json_format_col"
							span={12}
						>
							<Badge.Ribbon text="Correct" color="green">
								<div className="json_format">
									<Image
										className="json_format_image"
										alt="correct json format 1"
										src={correctJsonFormat1}
										onClick={() => {
											setImageSrc(correctJsonFormat1);
											setImageModal(true);
										}}
										preview={false}
									/>
								</div>
							</Badge.Ribbon>
						</Col>
						<Col
							xs={24}
							xl={12}
							className="json_format_col"
							span={12}
						>
							<Badge.Ribbon text="Correct" color="green">
								<div className="json_format">
									<Image
										className="json_format_image"
										src={correctJsonFormat2}
										onClick={() => {
											setImageSrc(correctJsonFormat2);
											setImageModal(true);
										}}
										preview={false}
									/>
								</div>
							</Badge.Ribbon>
						</Col>
					</Row>
					<Row>
						<Col
							xs={24}
							xl={12}
							className="json_format_col"
							span={12}
						>
							<Badge.Ribbon text="Correct" color="green">
								<div className="json_format">
									<Image
										className="json_format_image"
										alt="correct json format 3"
										src={correctJsonFormat3}
										onClick={() => {
											setImageSrc(correctJsonFormat3);
											setImageModal(true);
										}}
										preview={false}
									/>
								</div>
							</Badge.Ribbon>
						</Col>
						<Col
							xs={24}
							xl={12}
							className="json_format_col"
							span={12}
						>
							<Badge.Ribbon text="Wrong" color="red">
								<div className="json_format">
									<Image
										className="json_format_image"
										alt="wrong json format 1"
										src={wrongJsonFormat1}
										onClick={() => {
											setImageSrc(wrongJsonFormat1);
											setImageModal(true);
										}}
										preview={false}
									/>
								</div>
							</Badge.Ribbon>
						</Col>
					</Row>
					<Row>
						<Col
							xs={24}
							xl={12}
							className="json_format_col"
							span={12}
						>
							<Badge.Ribbon text="Wrong" color="red">
								<div className="json_format">
									<Image
										className="json_format_image"
										alt="wrong json format 2"
										src={wrongJsonFormat2}
										onClick={() => {
											setImageSrc(wrongJsonFormat2);
											setImageModal(true);
										}}
										preview={false}
									/>
								</div>
							</Badge.Ribbon>
						</Col>
						<Col
							xs={24}
							xl={12}
							className="json_format_col"
							span={12}
						></Col>
					</Row>
				</Col>
				<Col xs={24} xl={8} className="url_col" span={8} />
			</Row>
		</>
	);
};
