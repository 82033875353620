import React, { ReactElement, useEffect, useState } from "react";
import {
	Tabs,
	Layout,
	Typography,
	Select,
	Divider,
	Space,
	Card,
	Button,
	Input,
	message,
	Form,
	Row,
	Col,
	Spin,
} from "antd";
import "./PageSider.less";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import { Chart } from "react-chartjs-2";
import faker from "@faker-js/faker";
import { MyBar, DataProps } from "../chartManager/BarChart";
import { SchemaField } from "../../services/api/models/url_model";
import { UrlRepo } from "../../services/api/repositories/url_repo";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Item } from "rc-menu";
import mixpanel from "mixpanel-browser";
const { Sider } = Layout;

const { Title, Text } = Typography;
const { Option } = Select;

interface PageSiderProps {
	// To allow functions in other page to handle the visibility of sider.
	siderVisibility: string;
	// To allow the functions in this page to handle the visibility of sider.
	toggleCollapsed: (visibility: string) => void;
	// Selected Graph Value state
	selectedGraph?: DataProps["data"];
	// Callback of setting the graph value
	setGraphValues: (data: Array<DataProps["data"]>) => void;
	// Graph Values state
	graphValues: Array<DataProps["data"]>;
	// Data Rows
	dataRows: any;
	schemaField: Array<SchemaField>;
	apiKey: string;
	userApiId: string;
}

export const PageSider: React.FC<PageSiderProps> = ({
	siderVisibility,
	setGraphValues,
	graphValues,
	selectedGraph,
	schemaField,
	apiKey,
	userApiId,
}) => {
	// Set dimensions
	const [dimensionValues, setDimensionValues] = useState<Array<string>>();
	// Set metrics
	const [metricsValues, setMetricsValues] = useState<Array<string>>();

	const [isLoading, setLoading] = useState<boolean>(false);

	let urlRepo = new UrlRepo();

	const [form] = Form.useForm();

	async function onFinish(values: any) {
		setLoading(true);
		mixpanel.track("Sider_Excecute_Generate_Data_Click");

		let apiResult = await urlRepo.generateDataResponse({
			userApiId: userApiId,
			apiKey: apiKey,
			dimensionArray: values["dimensionValues"],
			metricsArray: values["metrics"].map(
				(item: {
					name: string;
					operation: string;
					graph_type: string;
				}) => {
					return item.name;
				}
			),
			metricsOperation: values["metrics"].map(
				(item: {
					name: string;
					operation: string;
					graph_type: string;
				}) => {
					switch (item.operation) {
						case "sum":
							item.operation = "sum";
							break;
						case "cnt":
							item.operation = "count";
							break;
						case "avg":
							item.operation = "mean";
							break;

						default:
							break;
					}
					return item.operation;
				}
			),
		});

		if (apiResult.isSuccess) {
			mixpanel.track("Sider_Excecute_Generate_Data_Click", {
				apiResult: apiResult.message,
			});
			graphValues = graphValues.map((item) => {
				if (item.key === selectedGraph?.key)
					return {
						...selectedGraph,
						title: values["title"],
						dataSet:
							Object.entries(apiResult.data).length > 1
								? Object.entries(apiResult.data)
										.slice(1)!
										.map((item: any, index) => {
											return {
												graph_type:
													values["metrics"][index][
														"graph_type"
													],
												label: item[0],
												metrics: item[1],
											};
										})
								: [],
						dimension: [
							{
								name: values["dimensionValues"],
								data: apiResult.data[
									Object.keys(apiResult.data)[0]
								],
							},
						],
						chosen_schema: values["metrics"],
					};

				return item;
			});
			setGraphValues(graphValues);
			setLoading(false);
		} else {
			mixpanel.track("Sider_Excecute_Generate_Data_Click", {
				apiResult: apiResult.message,
			});

			setLoading(false);
		}
	}

	useEffect(() => form.resetFields(), [selectedGraph]);

	return (
		<Sider
			className="page_sider"
			style={{ display: siderVisibility }}
			width="330"
			theme="light"
		>
			<div>
				{selectedGraph === undefined ? (
					<p>Please select a graph</p>
				) : (
					<Form
						name="page_sider_form"
						className="page_sider_form"
						initialValues={{
							title: selectedGraph.title,
							metrics: selectedGraph.chosen_schema,
							dimensionValues: selectedGraph.dimension[0]?.name,
						}}
						onFinish={onFinish}
						form={form}
					>
						<Title level={5}>{selectedGraph.title}</Title>

						<Title level={5}>Title</Title>

						{/* Input title */}
						<Form.Item
							name="title"
							rules={[
								{
									required: true,
									message: "Missing Title",
								},
							]}
						>
							<Input
								className="input_bar"
								type="text"
								placeholder="Graph Label"
								name="label"
							/>
						</Form.Item>

						<Divider />

						{/* Select X-axis */}
						<Title level={5}>Dimension</Title>
						<Form.Item
							name={["dimensionValues"]}
							rules={[
								{
									required: true,
									message: "Missing Dimension",
								},
							]}
						>
							<Select
								className="selection_bar"
								placeholder="Select Dimension"
								optionFilterProp="children"
							>
								{schemaField.map((item, index) => {
									return (
										<Option value={item.name!}>
											{item.labelName}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
						<Divider />

						{/* Select metrics */}
						<Title level={5}>Metrics</Title>
						<Form.List name="metrics">
							{(fields, { add, remove }) => (
								<div>
									{fields.map((field) => (
										<Row>
											<Col span={5} className="param_col">
												<Form.Item
													{...field}
													name={[
														field.name,
														"operation",
													]}
													rules={[
														{
															required: true,
															message:
																"Missing Operation",
														},
													]}
												>
													<Select
														className="selection_bar"
														placeholder="Opt"
														optionFilterProp="children"
													>
														{[
															"sum",
															"cnt",
															"avg",
														].map((item, index) => {
															return (
																<Option
																	value={item}
																	name={item}
																>
																	{item}
																</Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col>
											<Col
												span={12}
												className="param_col"
											>
												<Form.Item
													{...field}
													name={[field.name, "name"]}
													rules={[
														{
															required: true,
															message:
																"Missing Metrics",
														},
													]}
												>
													<Select
														className="selection_bar"
														placeholder="Select Metrics"
														optionFilterProp="children"
														
													>
														{schemaField.map(
															(item, index) => {
																return (
																	<Option
																		value={
																			item.name!
																		}
																	>
																		{
																			item.labelName
																		}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
											<Col span={5} className="param_col">
												<Form.Item
													{...field}
													name={[
														field.name,
														"graph_type",
													]}
													rules={[
														{
															required: true,
															message:
																"Missing Graph Type",
														},
													]}
												>
													<Select
														className="selection_bar"
														placeholder="Graph Type"
														optionFilterProp="children"
														
													>
														{[
															"bar",
															"line",
															"pie",
														].map((item, index) => {
															return (
																<Option
																	value={item}
																>
																	{item}
																</Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col>
											<Col
												xl={2}
												span={2}
												className="param_col"
											>
												<MinusCircleOutlined
													onClick={() =>
														remove(field.name)
													}
												/>
											</Col>
										</Row>
									))}
									<Form.Item>
										<Button
											type="dashed"
											onClick={() => add()}
											block
											icon={<PlusOutlined />}
										>
											Add sights
										</Button>
									</Form.Item>
								</div>
							)}
						</Form.List>

						<Divider />

						{/* Generate graph button */}
						<Form.Item>
							<Spin spinning={isLoading}>
								<Button
									type="ghost"
									size="large"
									shape="round"
									className="preview_button"
									htmlType="submit"
								>
									Update
								</Button>
							</Spin>
						</Form.Item>
					</Form>
				)}
			</div>
		</Sider>
	);
};
