import { Networking } from "../../networking";
import { Response } from "../../response";
import { File } from "../models/file_model";
import { ReportRepo } from "./report_repo";

export class FileRepo {
  private networking = new Networking();
  readonly userId: string;

  constructor(userId: string) {
    this.userId = userId;
  }

  async createFile(filename: string, folderID: string): Promise<Response> {
    let response = await this.networking.postData(
      `file/create_in/${folderID}`,
      {
        filename: filename,
        created_by: this.userId,
      },
      { "Content-Type": "application/json" }
    );

    return response;
  }

  async getUserFiles(): Promise<File[]> {
    let response = await this.networking.getData(
      `file/get_all_by_user/${this.userId}`
    );

    if (response.isSuccess) {
      let data: Array<Map<String, any>> = response.data;

      let files: File[] = data.map((x) => File.fromJson(x));

      for (var x of files) {
        x.preview = await ReportRepo.getReportPreview(x.fileId);
      }

      return files;
    }

    return [];
  }

  static async getFile(fileID: string): Promise<File | undefined> {
    let response = await new Networking().getData(`file/get_by_id/${fileID}`);

    if (response.isSuccess) {
      return File.fromJson(response.data);
    }
  }
}
