import React, { ReactElement, useEffect, useState } from "react";
import _ from "lodash";
import {
	Layout,
	Row,
	Col,
	Form,
	Card,
	message,
	Input,
	Space,
	Button,
	Modal,
	Divider,
	Spin,
} from "antd";
import "./Url.less";
import { BackHeader, EmailField, FormButton } from "../../utils/components";
import { FirebaseServices } from "../../utils/firebaseServices";
import { useHistory, useLocation } from "react-router-dom";
import { RoutePath } from "../../utils/routePath";
import { UrlRepo } from "../../services/api/repositories/url_repo";
import { LocalStorage } from "../../utils/localStorage";
import { v4 as uuidv4 } from "uuid";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";
import Paragraph from "antd/lib/typography/Paragraph";
import { url } from "inspector";
import {
	CopyOutlined,
	MinusCircleOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import { ConfigParam } from "../../services/api/models/url_model";
import mixpanel from "mixpanel-browser";

export const FillParam: React.FC<any> = () => {
	let history = useHistory();

	let location = useLocation();

	let urlRepo = new UrlRepo();

	const [form] = Form.useForm();

	const [isLoading, setLoading] = useState(true);

	const locationState: any = location.state;

	const [_url, setUrl] = useState(locationState.url);

	const onFinish = async (value: any) => {
		mixpanel.track("Fill_Param_Excecute_Add_Param_Click");
		let unextractedUrl = value["url"];
		var extractFromBracket = /\((.*?)\)/gi;

		let configParamList =
			unextractedUrl.match(extractFromBracket) !== null &&
			unextractedUrl.match(extractFromBracket) !== undefined
				? unextractedUrl
						.match(extractFromBracket)
						.map((item: string) => {
							return item.replace("(", "").replace(")", "");
						})
				: [];

		let configParam =
			configParamList.length > 0
				? configParamList.map((item: string, index: number) => {
						if (item.includes("=")) {
							let tempArray = item.split("=");
							return { [tempArray[0]]: tempArray[1] };
						} else {
							return { [`Value~${item}`]: item };
						}
				  })
				: [];

		// If no param is added, proceed to next page
		if (configParam.length === 0) {
			history.push({
				pathname: RoutePath.fill_field,
				state: {
					url: locationState.url,
					userApiId: locationState.userApiId,
					apiKey: locationState.apiKey,
				},
			});
		} else {
			history.push({
				pathname: RoutePath.fill_field,
				state: {
					url: locationState.url,
					apiKey: locationState.apiKey,
					userApiId: locationState.userApiId,
					configParam: configParam,
				},
			});
		}
	};

	useEffect(() => {
		async function initState() {
			let apiResult = await urlRepo.getConfigParamByApiKey({
				apiKey: locationState.apiKey,
			});

			if (apiResult.isSuccess) {
				let url = _url;
				apiResult.data.forEach((item: ConfigParam) => {
					if (item.name?.includes("Value")) {
						url = url.replace(
							`${item.defaultValue}`,
							`(${item.defaultValue})`
						);
					} else {
						url = url.replace(
							`${item.name}=${item.defaultValue}`,
							`(${item.name}=${item.defaultValue})`
						);
					}
				});
				setUrl(url);
			}
			setLoading(false);
		}

		initState();
	}, []);

	useEffect(() => form.resetFields(), [_url]);

	return (
		<Layout className="layout" style={{ overflow: "auto" }}>
			<div className="navbar" style={{ backgroundColor: "#fff" }}>
				<BackHeader title="Fill in the Parammeter (Optional)" />
			</div>
			<Layout
				className="url_content"
				style={{ backgroundColor: "white" }}
			>
				<Row className="url_row">
					<Col xs={24} xl={8} className="url_col" span={8} />
					<Col xs={24} xl={8} className="url_col" span={8}>
						<Space direction="vertical">
							<Paragraph>
								The parammeter holds the dynamic value which
								allows the embedded report to retrieve the
								customary data.
							</Paragraph>
							<Text>Example:</Text>
							<Paragraph>
								<Text type="secondary">
									https://www.yourdomain.com/getdata
									<Text strong>
										?user_id=default&time=default
									</Text>
								</Text>
							</Paragraph>
							<Text>{"<or>"}</Text>
							<Paragraph>
								<Text type="secondary">
									https://www.yourdomain.com/getdata/
									<Text strong>uid123456</Text>
								</Text>
							</Paragraph>
						</Space>
						<Divider></Divider>
						<Space direction="vertical">
							<Paragraph>
								<Text>
									Use
									<Text keyboard>(</Text>
									<Text keyboard>)</Text>
									to wrap the parameters which is dynamic.
								</Text>
							</Paragraph>
							<Text>Example:</Text>
							<Paragraph>
								<Text type="secondary">
									https://www.yourdomain.com/getdata
									<Text strong>
										?<Text type="danger">(</Text>
										user_id=default
										<Text type="danger">)</Text>&
										<Text type="danger">(</Text>
										time=default
										<Text type="danger">)</Text>
									</Text>
								</Text>
							</Paragraph>
							<Text>{"<or>"}</Text>
							<Paragraph>
								<Text type="secondary">
									https://www.yourdomain.com/getdata/
									<Text type="danger">(</Text>
									<Text strong>uid123456</Text>
									<Text type="danger">)</Text>
								</Text>
							</Paragraph>
						</Space>
						<Card>
							<Form
								name="fill_url"
								className="url_form"
								initialValues={{ url: _url }}
								onFinish={onFinish}
								form={form}
							>
								<Spin spinning={isLoading}>
									<Form.Item
										name={"url"}
										rules={[
											{
												required: true,
												message: "Missing URL",
											},
										]}
									>
										<Input placeholder="Key" />
									</Form.Item>
								</Spin>

								<Form.Item>
									<FormButton
										title="Next"
										isLoading={false}
									/>
								</Form.Item>
							</Form>
						</Card>
					</Col>
					<Col xs={24} xl={8} className="url_col" span={8} />
				</Row>
			</Layout>
		</Layout>
	);
};
