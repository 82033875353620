import {
	BrowserRouter,
	Redirect,
	Route,
	Switch,
	useHistory,
	useLocation,
} from "react-router-dom";
import { Home } from "./pages/home/Home";
import { ForgotPassword } from "./pages/login/ForgotPassword";
import { Login } from "./pages/login/Login";
import { CheckoutPayment } from "./pages/payment/CheckoutPayment";
import { ProductChoices } from "./pages/payment/ProductChoices";
import { EmailVerification } from "./pages/register/EmailVerification";
import { Register } from "./pages/register/Register";
import { ReportPage } from "./pages/report/report";
import { Contact } from "./pages/support/Contact";
import { RoutePath } from "./utils/routePath";
import { UserProfile } from "./pages/profile/UserProfile";
import { ResetPassword } from "./pages/profile/ResetPassword";
import { FillUrl } from "./pages/url/FillUrl";
import { FillParam } from "./pages/url/FillParam";
import { FillField } from "./pages/url/FillField";
import { MainDashboard } from "./pages/dashboard/MainDashboard";
import { GraphEditor } from "./pages/graph_editor/GraphEditor";
import { useContext, useEffect, useState } from "react";
import { GraphDisplay } from "./pages/graph_display/GraphDisplay";
import { AuthContext } from "./utils/AuthContext";
import { LocalStorage } from "./utils/localStorage";
import mixpanel from "mixpanel-browser";

interface AppProps {}

export const App: React.FC<AppProps> = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route path={RoutePath.default} exact component={Home} />
				<Route path={RoutePath.login} exact component={Login} />
				<Route path={RoutePath.register} exact component={Register} />
				<Route
					path={RoutePath.forgot_password}
					exact
					component={ForgotPassword}
				/>
				<Route path={RoutePath.home} exact component={Home} />
				<Route
					path={RoutePath.email_verification}
					exact
					component={EmailVerification}
				/>
				<ProtectedRoute
					path={RoutePath.dashboard}
					exact
					component={MainDashboard}
				/>
				)
				<Route
					path={RoutePath.product_choices}
					exact
					component={ProductChoices}
				/>
				<Route
					path={RoutePath.checkout_payment}
					exact
					component={CheckoutPayment}
				/>
				<ProtectedRoute
					path={RoutePath.user_profile}
					exact
					component={UserProfile}
				/>
				<ProtectedRoute
					path={RoutePath.report}
					exact
					component={ReportPage}
				/>
				<ProtectedRoute
					path={RoutePath.reset_password}
					exact
					component={ResetPassword}
				/>
				<ProtectedRoute
					path={RoutePath.fill_url}
					exact
					component={FillUrl}
				/>
				<ProtectedRoute
					path={RoutePath.fill_param}
					exact
					component={FillParam}
				/>
				<ProtectedRoute
					path={RoutePath.fill_field}
					exact
					component={FillField}
				/>
				<ProtectedRoute
					path={RoutePath.graph_editor}
					exact
					component={GraphEditor}
				/>
				{/* Wthout Protector is to This allows the graph viewr to access the graph without login */}
				<Route
					path={RoutePath.graph_display}
					exact
					component={GraphDisplay}
				/>
			</Switch>
		</BrowserRouter>
	);
};

// Auth Protector
const ProtectedRoute = (props: any) => {
	mixpanel.people.set({});
	return LocalStorage.getAuth() ? (
		LocalStorage.getEmailVerified() === "false" ? (
			<Redirect exact to={RoutePath.email_verification} />
		) : LocalStorage.getPackagePlan() === "free" ? (
			<Redirect exact to={RoutePath.product_choices}  />
		) : (
			<Route {...props} />
		)
	) : (
		<Redirect exact  to={RoutePath.default}  />
	);
};
