import React, { useState } from "react";
import _ from "lodash";
import { Layout, Row, Col, Form, Checkbox, Input, Card, Radio } from "antd";
import "./Register.less";
import { useHistory } from "react-router-dom";
import "antd-country-phone-input/dist/index.css";
import { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/en/world.json";
import { FirebaseServices } from "../../utils/firebaseServices";
import { UserRepo } from "../../services/api/repositories/user_repo";
import {
	BackHeader,
	ConfirmPasswordField,
	EmailField,
	FormButton,
	FormPhoneInput,
	PasswordField,
} from "../../utils/components";
import { Encryption } from "../../utils/encryption";
import { RoutePath } from "../../utils/routePath";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import mixpanel from "mixpanel-browser";

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

export const Register: React.FC<any> = () => {
	let userRepo = new UserRepo();

	let history = useHistory();

	// Is loading flag
	const [isLoading, setLoading] = useState(false);

	const onFinish = async (values: any) => {
		setLoading(true);

		mixpanel.track("Register_Execute_Sign_Up_Click");

		let firebaseResult = await FirebaseServices.createAccount(
			values["email"],
			values["password"]
		);

		if (firebaseResult.isSuccess) {
			mixpanel.identify(firebaseResult.data.uid);
			mixpanel.track("Register_Execute_Sign_Up_Click", {
				firebaseResult: firebaseResult.message,
			});

			let apiResult = await userRepo.saveUser({
				purpose: "new_user",
				userId: firebaseResult.data.uid,
				email: values["email"],
				password: Encryption.encrypt(values["password"]),
				nickname: values["nickname"],
				phoneNo: formatPhoneNumberIntl(values["phone_number"]),
				acctType: values["account_type"],
				orgName: values["organisation_name"],
				packagePlan: "free",
			});

			if (apiResult.isSuccess) {
				alert("Email verification has sent to your mail box.");
				mixpanel.track("Register_Execute_Sign_Up_Click", {
					apiResult: apiResult.message,
				});
				mixpanel.people.set({
					Email: values["email"],
					Nickname: values["nickname"],
					PhoneNo: formatPhoneNumberIntl(values["phone_number"]),
					AcctType: values["account_type"],
					OrgName: values["organisation_name"],
					PackagePlan: "free",
				});
				history.replace(RoutePath.product_choices);
				setLoading(false);
			} else {
				mixpanel.track("Register_Execute_Sign_Up_Click", {
					apiResult: apiResult.message,
				});
				alert(apiResult.message);
				setLoading(false);
			}
		} else {
			mixpanel.track("Register_Execute_Sign_Up_Click", {
				firebaseResult: firebaseResult.message,
			});
			alert(firebaseResult.message);
			setLoading(false);
		}
	};

	return (
		<Layout className="register_layout" style={{ overflow: "auto" }}>
			<div
				className="register_navbar"
				style={{ backgroundColor: "#fff" }}
			>
				<BackHeader title="Register" />
			</div>
			<Layout
				className="register_content"
				style={{ backgroundColor: "white" }}
			>
				<Row className="register_form_row">
					<Col
						xs={24}
						xl={8}
						className="register_form_col"
						span={8}
					/>
					<Col xs={24} xl={8} className="register_form_col" span={8}>
						<Card>
							<ConfigProvider locale={en}>
								<Form
									{...formItemLayout}
									name="register"
									className="register_form"
									initialValues={{ remember: true }}
									onFinish={onFinish}
								>
									<EmailField />

									<PasswordField />

									<ConfirmPasswordField />

									<Form.Item
										name="nickname"
										label="Nickname"
										tooltip="What do you want others to call you?"
										rules={[
											{
												required: true,
												message:
													"Please input your nickname!",
												whitespace: true,
											},
										]}
									>
										<Input />
									</Form.Item>

									<FormPhoneInput />
									<Form.Item
										label="Type"
										name="account_type"
										rules={[
											{
												required: true,
												message:
													"Please choose your account type!",
											},
										]}
									>
										<Radio.Group>
											<Radio.Button value="institute">
												Institute
											</Radio.Button>
											<Radio.Button value="company">
												Company
											</Radio.Button>
										</Radio.Group>
									</Form.Item>
									<Form.Item
										label="Org Name"
										name="organisation_name"
										tooltip="Please input your Company/Institution name"
										rules={[
											{
												required: true,
												message:
													"Please input your Company/Institution name!",
											},
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										name="agreement"
										valuePropName="checked"
										rules={[
											{
												validator: (_, value) =>
													value
														? Promise.resolve()
														: Promise.reject(
																new Error(
																	"Should accept agreement"
																)
														  ),
											},
										]}
										{...tailFormItemLayout}
									>
										<Checkbox>
											I have read the{" "}
											<a
												href="https://codagence.notion.site/TERMS-AND-CONDITIONS-416ed24afab14050a68e2f0d80357f65"
												onClick={() => {
													mixpanel.track(
														"Home_View_T&C_Click"
													);
												}}
											>
												agreement
											</a>
										</Checkbox>
									</Form.Item>
									<Form.Item {...tailFormItemLayout}>
										<FormButton
											title="Register"
											isLoading={isLoading}
										/>
									</Form.Item>
								</Form>
							</ConfigProvider>
						</Card>
					</Col>
					<Col
						xs={24}
						xl={8}
						className="register_form_col"
						span={8}
					/>
				</Row>
			</Layout>
		</Layout>
	);
};
