import React, { ReactElement, useState } from "react";
import _ from "lodash";
import { Layout, Row, Col, Form, Card } from "antd";
import "./ForgotPassword.less";
import { BackHeader, EmailField, FormButton } from "../../utils/components";
import { FirebaseServices } from "../../utils/firebaseServices";
import { useHistory } from "react-router-dom";
import { RoutePath } from "../../utils/routePath";
import mixpanel from "mixpanel-browser";

export const ForgotPassword: React.FC<any> = () => {
	let history = useHistory();

	const [isLoading, setLoading] = useState(false);

	const onFinish = async (values: any) => {
		setLoading(true);
		mixpanel.track("Forgot_Password_Excecute_Reset_Password_Click");
		let firebaseResult = await FirebaseServices.resetPassword(
			values["email"]
		);

		if (firebaseResult.isSuccess) {
			// Go to login page
			mixpanel.track("Forgot_Password_Excecute_Reset_Password_Click", {
				Response: firebaseResult.message,
			});

			history.push(RoutePath.login);
			setLoading(false);
		} else {
			mixpanel.track("Forgot_Password_Excecute_Reset_Password_Click", {
				Response: firebaseResult.message,
			});

			alert(firebaseResult.message);
			setLoading(false);
		}
	};

	return (
		<Layout className="layout" style={{ overflow: "auto" }}>
			<div className="navbar" style={{ backgroundColor: "#fff" }}>
				<BackHeader title="Forgot Password" />
			</div>
			<Layout
				className="forget_password_content"
				style={{ backgroundColor: "white" }}
			>
				<Row className="forgot_password_row">
					<Col
						xs={24}
						xl={8}
						className="forgot_password_col"
						span={8}
					/>
					<Col
						xs={24}
						xl={8}
						className="forgot_password_col"
						span={8}
					>
						<Card>
							<Form
								name="forgot_password"
								className="forgot_password_form"
								initialValues={{ remember: true }}
								onFinish={onFinish}
							>
								<EmailField />
								<Form.Item>
									<FormButton
										title="Send Email Verification"
										isLoading={isLoading}
									/>
								</Form.Item>
							</Form>
						</Card>
					</Col>
					<Col
						xs={24}
						xl={8}
						className="forgot_password_col"
						span={8}
					/>
				</Row>
			</Layout>
		</Layout>
	);
};
