import { Card, Col, Form, Input, Layout, Radio, Row } from "antd";
import { ConfigProvider } from "antd-country-phone-input";
import "antd-country-phone-input/dist/index.css";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import en from "world_countries_lists/data/en/world.json";
import { User } from "../../services/api/models/user_model";
import { UserRepo } from "../../services/api/repositories/user_repo";
import { BackHeader, FormButton, FormPhoneInput } from "../../utils/components";
import { LocalStorage } from "../../utils/localStorage";
import { RoutePath } from "../../utils/routePath";
import "./UserProfile.less";

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

export const UserProfile: React.FC<any> = () => {
	let userRepo = new UserRepo();

	let history = useHistory();

	// Is btn loading flag
	const [isLoading, setLoading] = useState(false);

	// Is card loading flag
	const [isCardLoading, setCardLoading] = useState(true);

	const [userData, setUserData] = useState(new User({}));

	const onFinish = async (values: any) => {
		let userId = await LocalStorage.getUserID();
		setLoading(true);
		mixpanel.track("Profile_Excecute_Update_Profile_Click");

		let apiResult = await userRepo.saveUser({
			purpose: "update",
			userId: userId!,
			nickname: values["nickname"],
			phoneNo: formatPhoneNumberIntl(values["phone_number"]),
			acctType: values["account_type"],
			orgName: values["organisation_name"],
		});

		if (apiResult.isSuccess) {
			alert("Update Success");
			mixpanel.track("Profile_Excecute_Update_Profile_Click", {
				apiResult: apiResult.message,
			});

			mixpanel.people.set({
				Nickname: values["nickname"],
				PhoneNo: formatPhoneNumberIntl(values["phone_number"]),
				AcctType: values["account_type"],
				OrgName: values["organisation_name"],
			});
			history.replace(RoutePath.home);
			setLoading(false);
		} else {
			mixpanel.track("Profile_Excecute_Update_ProfileClick", {
				apiResult: apiResult.message,
			});

			alert(apiResult.message);
			setLoading(false);
		}
	};

	useEffect(() => {
		async function fetchData() {
			let userId = await LocalStorage.getUserID();
			let apiResult = await userRepo.getUserById({
				userId: userId!,
			});
			if (apiResult.isSuccess) {
				setUserData(apiResult.data[0]);
			} else {
				alert(apiResult.message);
			}
			setCardLoading(false);
		}
		fetchData();
	}, []);

	return (
		<Layout className="layout" style={{ overflow: "auto" }}>
			<div className="navbar" style={{ backgroundColor: "#fff" }}>
				<BackHeader title="Profile" />
			</div>
			<Layout
				className="profile_content"
				style={{ backgroundColor: "white" }}
			>
				<Row className="user_profile_row">
					<Col xs={24} xl={8} className="user_profile_col" span={8} />
					<Col xs={24} xl={8} className="user_profile_col" span={8}>
						<Card loading={isCardLoading}>
							<ConfigProvider locale={en}>
								<Form
									{...formItemLayout}
									name="register"
									className="user_profile_form"
									initialValues={{
										nickname: userData.nickname ?? "",
										phone_number: userData.phoneNo ?? "",
										account_type: userData.acctType ?? "",
										organisation_name:
											userData.orgName ?? "",
									}}
									onFinish={onFinish}
								>
									<Form.Item
										name="nickname"
										label="Nickname"
										tooltip="What do you want others to call you?"
										rules={[
											{
												required: true,
												message:
													"Please input your nickname!",
												whitespace: true,
											},
										]}
									>
										<Input />
									</Form.Item>
									<FormPhoneInput />
									<Form.Item
										label="Type"
										name="account_type"
										rules={[
											{
												required: true,
												message:
													"Please choose your account type!",
											},
										]}
									>
										<Radio.Group>
											<Radio.Button value="institute">
												Institute
											</Radio.Button>
											<Radio.Button value="company">
												Company
											</Radio.Button>
										</Radio.Group>
									</Form.Item>
									<Form.Item
										label="Org Name"
										name="organisation_name"
										tooltip="Please input your Company/Institution name"
										rules={[
											{
												required: true,
												message:
													"Please input your Company/Institution name!",
											},
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item {...tailFormItemLayout}>
										<FormButton
											title="Update"
											isLoading={isLoading}
										/>
									</Form.Item>
								</Form>
							</ConfigProvider>
						</Card>
					</Col>
					<Col xs={24} xl={8} className="user_profile_col" span={8} />
				</Row>
			</Layout>
		</Layout>
	);
};
