import {
  AntDesignOutlined,
  AppstoreFilled,
  PlusSquareFilled,
  QuestionCircleOutlined,
  ShareAltOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Breadcrumb, Button, Input, Layout, Menu, Tooltip } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
import { FC, useEffect, useState } from "react";
import "./report.less";
import { useParams } from "react-router-dom";
import { ReportRepo } from "../../services/api/repositories/report_repo";
import { Report } from "../../services/api/models/report_model";
import Modal from "antd/lib/modal/Modal";
import { FileRepo } from "../../services/api/repositories/file_repo";
import { File } from "../../services/api/models/file_model";
import no_content from "../../assets/illustrations/no-content.png";

export const ReportPage: FC = () => {
  let params = useParams<{ id: string }>();
  let reportRepo = new ReportRepo(params.id);

  const [file, setFile] = useState<File | undefined>();
  const [inputs, setInputs] = useState<{ title: string; url: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [reports, setReports] = useState<Array<Report>>([]);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    async function init() {
      setReports(await reportRepo.getReports());
      setFile(await FileRepo.getFile(params.id));
    }

    init();
  }, []);

  function addReportDialog(): JSX.Element {
    async function onOk() {
      if (inputs && inputs?.title.length !== 0 && inputs.url.length !== 0) {
        setLoading(true);

        let response = await reportRepo.createReport(inputs.title, inputs.url);

        setReports(await reportRepo.getReports());

        setLoading(false);
      }

      setShowDialog(false);
    }

    return (
      <Modal
        title="Add New Report"
        visible={showDialog}
        onOk={onOk}
        confirmLoading={loading}
        onCancel={() => setShowDialog(false)}
      >
        <Title level={5}>Title</Title>
        <Input
          required={true}
          placeholder="Enter report title"
          value={inputs?.title}
          onChange={(x) =>
            setInputs({ title: x.target.value, url: inputs?.url ?? "" })
          }
        ></Input>
        <Title level={5} style={{ marginTop: "10px" }}>
          URL
        </Title>
        <Input
          required={true}
          placeholder="Enter report embeded url"
          value={inputs?.url}
          onChange={(x) =>
            setInputs({ title: inputs?.title ?? "", url: x.target.value })
          }
        ></Input>
      </Modal>
    );
  }

  return (
    <Layout>
      <Header id="appbar">
        <div id="caramel-analytic-logo" placeholder="Caramel Analytic">
          <Title className="text" level={4}>
            Caramel Analytic
          </Title>
        </div>
        <Menu
          id="menu"
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["0"]}
          onClick={(e) => setIndex(parseInt(e.key))}
        >
          {reports.map((report, index) => (
            <Menu.Item key={index}>{report.title}</Menu.Item>
          ))}
        </Menu>
        <PlusSquareFilled
          className="add-icon"
          style={{ color: "white" }}
          onClick={(x) => {
            setInputs({ title: "", url: "" });

            setShowDialog(true);
          }}
        />
        <div className="right-align profile-icon">
          <Avatar className="icon" size={30} icon={<UserOutlined />} />
        </div>
        <QuestionCircleOutlined
          className="right-align appbar-icon"
          style={{ color: "white" }}
          placeholder="Question"
        />
        <AppstoreFilled
          className="right-align appbar-icon"
          style={{ color: "white" }}
          placeholder="add report"
        />
        <div className="right-align vertical-divider" />
        <div className="right-align">
          <Button type="primary" icon={<ShareAltOutlined />} size="large">
            Share
          </Button>
        </div>
        <Avatar.Group
          maxCount={4}
          className="right-align avatar-group"
          maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
        >
          <Avatar src="https://joeschmoe.io/api/v1/random" />
          <Avatar style={{ backgroundColor: "#f56a00" }}>K</Avatar>
          <Tooltip title="Ant User" placement="top">
            <Avatar
              style={{ backgroundColor: "#87d068" }}
              icon={<UserOutlined />}
            />
          </Tooltip>
          <Avatar
            style={{ backgroundColor: "#1890ff" }}
            icon={<AntDesignOutlined />}
          />
        </Avatar.Group>
      </Header>
      <Layout>
        <Content className="content">
          <div className="content">
            {addReportDialog()}
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
              <Breadcrumb.Item>{file?.filename}</Breadcrumb.Item>
            </Breadcrumb>
            {reports[index] ? (
              <iframe
                className="embedded-report"
                title="report"
                allowFullScreen
                width={"100%"}
                src={reports[index].url}
                style={{ border: "0" }}
              />
            ) : (
              <div className="embedded-report">
                <img src={no_content} alt="No content found!" width={"500px"} height={"400px"}/>
              </div>
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
