export class ChartColor {
	static colorOptions = [
		// Pastellea
		"#fe9c8f",
		"#feb2a8",
		"#fec8c1",
		"#fad9c1",
		"#f9caa7",
		// Number 3
		"#2a4d69",
		"#4b86b4",
		"#adcbe3",
		"#e7eff6",
		"#63ace5",
		// Android Lolipop
		"#009688",
		"#35a79c",
		"#54b2a9",
		"#65c3ba",
		"#83d0c9",
		// Anime Skin Tones
		"#ffe9dc",
		"#fce9db",
		"e0a899",
		"#dfa290",
		"#c99789",
		// Capucino
		"#4b3832",
		"#854442",
		"#fff4e6",
		"#3c2f2f",
		"#be9b7b",
		// Greyblue
		"#3385c6",
		"#4279a3",
		"#476c8a",
		"#49657b",
		"#7f8e9e",
		// Pastel Rainbow
		"#a8e6cf",
		"#dcedc1",
		"#ffd3b6",
		"#ffaaa5",
		"#ff8b94",
	];
}
