import React, { useContext, useState } from "react";
import _ from "lodash";
import { Layout, Row, Col, Form, Checkbox, Card } from "antd";
import "./Login.less";
import { useHistory } from "react-router-dom";
import { FirebaseServices } from "../../utils/firebaseServices";
import { AuthContext } from "../../utils/AuthContext";
import {
    BackHeader,
    EmailField,
    FormButton,
    PasswordField,
} from "../../utils/components";
import { UserRepo } from "../../services/api/repositories/user_repo";
import { RoutePath } from "../../utils/routePath";
import { Encryption } from "../../utils/encryption";
import mixpanel from "mixpanel-browser";

const formItemLayout = {
    labelCol: {
        xs: { span: 22 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 22 },
        sm: { span: 14 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 22,
            offset: 0,
        },
        sm: {
            span: 14,
            offset: 6,
        },
    },
};

export const Login: React.FC<any> = () => {
    let userRepo = new UserRepo();

    let history = useHistory();

    // Get current sign in User
    const user = useContext(AuthContext);

    // Is loading flag
    const [isLoading, setLoading] = useState(false);

    const onFinish = async (values: any) => {
        setLoading(true);
        mixpanel.track("Login_Excecute_Log_In_Click")

        let firebaseResult = await FirebaseServices.logIn(
            values["email"],
            values["password"]
        );
        if (firebaseResult.isSuccess) {
            mixpanel.identify(firebaseResult.data.uid);
            mixpanel.track("Login_Excecute_Log_In_Click",{FirebaseResponse: firebaseResult.message})

            let apiResult = await userRepo.saveUser({
                purpose: "login",
                userId: firebaseResult.data.uid,
                email: values["email"],
                password: Encryption.encrypt(values["password"]),
            });

            if (apiResult.isSuccess) {
                mixpanel.track("Login_Excecute_Log_In_Click",{apiResult: apiResult.message})

                // Check if the user has verify the email
                if (firebaseResult.data?.emailVerified) {
                    // Go to home
                    mixpanel.track("Login_View_Home_Proceed");
                    alert("Log In success");
                    history.replace(RoutePath.home);
                } else {
                    // Go to email verification page
                    mixpanel.track("Login_View_Email_Verification_Proceed");
                    history.replace(RoutePath.email_verification);
                }
            } else {
                mixpanel.track("Login_Excecute_Log_In_Click",{apiResult: apiResult.message})

                alert(apiResult.message);
            }
        } else {
            mixpanel.track("Login_Excecute_Log_In_Click",{FirebaseResponse: firebaseResult.message})

            alert(firebaseResult.message);
        }
        setLoading(false);
    };

    return (
        <Layout className="layout" style={{ overflow: "auto" }}>
            <div className="navbar" style={{ backgroundColor: "#fff" }}>
                <BackHeader title="Log In" />
            </div>
            <Layout className="login_content" style={{ backgroundColor: "white" }}>
                <Row className="login_form_row">
                    <Col xs={24} xl={8} className="login_form_col" span={8} />
                    <Col xs={24} xl={8} className="login_form_col" span={8}>
                        <Card>
                            <Form
                                name="normal_login"
                                className="login_form"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                {...formItemLayout}
                            >
                                <EmailField />
                                <PasswordField />
                                <Form.Item {...tailFormItemLayout}>
                                    <Form.Item
                                        name="remember"
                                        valuePropName="checked"
                                        noStyle
                                    >
                                        <Checkbox>Remember me</Checkbox>
                                    </Form.Item>
                                    <div>
                                        <a
                                            className="login_form_forgot_pwd"
                                            href={RoutePath.forgot_password}
                                            onClick={()=>{
                                                mixpanel.track("Login_View_Forgot_Password_Click");
                                            }}
                                        >
                                            Forgot password
                                        </a>
                                    </div>
                                </Form.Item>

                                <Form.Item {...tailFormItemLayout}>
                                    <FormButton
                                        title="Log In"
                                        isLoading={isLoading}
                                    />
                                    <div>
                                        Or{" "}
                                        {/* The href is not inserted in the <a> tag to allow the replacingg of log in page when the user click on the link*/}
                                        <a
                                            onClick={() => {
                                                mixpanel.track("Login_View_Sign_Up_Click");
                                                history.replace(
                                                    RoutePath.register
                                                );
                                            }}
                                        >
                                            register now!
                                        </a>
                                    </div>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col xs={24} xl={8} className="login_form_col" span={8} />
                </Row>
            </Layout>
        </Layout>
    );
};
