import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import mixpanel from 'mixpanel-browser';


const firebaseConfig = {
  apiKey: "AIzaSyBVevQPa5E9j7DMqXc31ktChWJ_cKsmD9w",
  authDomain: "codagence.firebaseapp.com",
  projectId: "codagence",
  storageBucket: "codagence.appspot.com",
  messagingSenderId: "550164990740",
  appId: "1:550164990740:web:2f6eb30c709b8b820d1001",
  measurementId: "G-QM2TQK9ZS9"
};

mixpanel.init('73d1aa616f4d69439f5b5b354529899e'); 

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export const auth = getAuth(firebaseApp);
