import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import {
	Button,
	Layout,
	Menu,
	Typography,
	Row,
	Col,
	Space,
	Divider,
	Card,
	List,
	Avatar,
	message,
	Skeleton,
	Popconfirm,
	Spin,
	Tooltip,
} from "antd";
import "./MainDashboard.less";
import codagenceWordLogo from "../../assets/logo/Codagence_main_logo.png";
import { RoutePath } from "../../utils/routePath";
import { AuthContext } from "../../utils/AuthContext";
import { LocalStorage } from "../../utils/localStorage";
import { FirebaseServices } from "../../utils/firebaseServices";
import { PricingCards } from "../../utils/components";
import { PaymentRepo } from "../../services/api/repositories/payment_repo";
import { UserRepo } from "../../services/api/repositories/user_repo";
import { User } from "../../services/api/models/user_model";
import Meta from "antd/lib/card/Meta";
import {
	CopyOutlined,
	CopyTwoTone,
	DeleteOutlined,
	EyeInvisibleOutlined,
	PlusCircleOutlined,
} from "@ant-design/icons";
import { UserUrl } from "../../services/api/models/url_model";
import { UrlRepo } from "../../services/api/repositories/url_repo";
import Nav from "./Nav";
import mixpanel from "mixpanel-browser";
import { Link } from "@chakra-ui/react";

const { SubMenu } = Menu;
const { Header } = Layout;
const { Title } = Typography;

export const MainDashboard: React.FC<any> = () => {
	let userRepo = new UserRepo();

	let urlRepo = new UrlRepo();

	let history = useHistory();

	// Get current sign in User
	const user = useContext(AuthContext);

	// Handle the state of each option in Menu bar

	const [_userApiId, setUserApiId] = useState<string>("");

	const [_urls, setUrls] = useState<Array<UserUrl>>([]);

	const [_listLoading, setListLoading] = useState<boolean>(true);

	async function getUrls() {
		let userId = await LocalStorage.getUserID();
		let apiResult = await urlRepo.getAllUserUrlByUserId({
			userId: userId!,
		});

		if (apiResult.isSuccess) {
			if (apiResult.data.length > 0)
				apiResult.data.forEach((element: UserUrl) => {
					setUrls((prevState) => {
						return [...prevState, element];
					});
				});
		}
		setListLoading(false);
	}

	useEffect(() => {
		async function initState() {
			let userId = await LocalStorage.getUserID();
			let apiResult = await userRepo.getUserById({
				userId: userId!,
			});
			if (apiResult.isSuccess) {
				let usermodel: User = apiResult.data[0];
				await LocalStorage.setEmail(usermodel?.email ?? "");
				await LocalStorage.setNickName(usermodel?.nickname ?? "");
				await LocalStorage.setPackagePlan(
					usermodel?.packagePlan ?? "free"
				);
				setUserApiId(usermodel?.userApiId ?? "");
			}
		}

		initState();
		getUrls();

		return () => {
			setUserApiId(""); // This worked for me
			setUrls([]);
			setListLoading(true);
		};
	}, []);

	return (
		<>
			<Nav />
			<Layout>
				<Row className="dashboard_row">
					<Col xs={24} xl={6} className="dashboard_col" span={6} />
					<Col xs={24} xl={12} className="dashboard_col" span={12}>
						<Button
							type="primary"
							shape="round"
							icon={<PlusCircleOutlined />}
							size={"large"}
							onClick={() => {
								mixpanel.track(
									"Dashboard_View_Add_New_Url_Click"
								);

								history.push({
									pathname: RoutePath.fill_url,
									state: { userApiId: _userApiId },
								});
							}}
						>
							Add Url
						</Button>
						<div style={{ height: "20px" }}></div>
						<Skeleton active loading={_listLoading}>
							<List
								itemLayout="horizontal"
								dataSource={_urls}
								renderItem={(item, index) => (
									<List.Item
										actions={[
											<Tooltip
												placement="topLeft"
												title="Copy graph iframe"
											>
												<Button
													shape="round"
													icon={
														<CopyTwoTone twoToneColor="#270949" />
													}
													size={"large"}
													onClick={async () => {
														mixpanel.track(
															"Dashboard_Execute_Copy_Url_Click"
														);
														let configParamResult =
															await urlRepo.getConfigParamByApiKey(
																{
																	apiKey:
																		item!
																			.apiKey ??
																		"",
																}
															);

														if (
															configParamResult.isSuccess
														) {
															// Merge all config params
															let mergedConfigParam =
																"";
															if (
																configParamResult
																	.data
																	.length > 0
															) {
																configParamResult.data?.forEach(
																	(
																		item: any
																	) => {
																		mergedConfigParam = `${mergedConfigParam}&${item.name}=${item.defaultValue}`;
																	}
																);
															}
															navigator.clipboard.writeText(
																`<iframe src='https://www.codagence.com/graph_display?apiKey=${item!.apiKey}${mergedConfigParam}' style='height:200px;width:300px;' title='Iframe Example'></iframe>` ??
																	"Empty Url"
															);
															message.success(
																"iframe is copied"
															);
														} else {
															message.error(
																"iframe copied failure"
															);
														}
													}}
												/>
											</Tooltip>,
											<Popconfirm
												title="Are you sure you want to delete?"
												onCancel={() => {
													mixpanel.track(
														"Dashboard_Execute_Delete_Url_Click",
														{
															Confirmation:
																"Deletion Cancel",
														}
													);
												}}
												onConfirm={async () => {
													mixpanel.track(
														"Dashboard_Execute_Delete_Url_Click",
														{
															Confirmation:
																"Deletion Approved",
														}
													);


													let apiResult =
														await urlRepo.deleteUrl(
															{
																urlId: item.urlId!,
															}
														);

													if (apiResult.isSuccess) {
														setListLoading(true);
														setUrls([]);
														getUrls();
														message.success(
															"Delete success"
														);
													} else {
														message.success(
															"Delete Fail"
														);
													}
												}}
											>
												<Button
													shape="round"
													icon={
														<DeleteOutlined
															style={{
																color: "red",
															}}
														/>
													}
													size={"large"}
												/>
											</Popconfirm>,
										]}
									>
										<List.Item.Meta
											avatar={
												<Avatar
													src={`https://picsum.photos/200/300?random=${index}`}
												/>
											}
											title={
												<Link
													onClick={() => {
														mixpanel.track(
															"Dashboard_View_Graph_Editor_Click"
														);
														history.push({
															pathname:
																RoutePath.graph_editor,
															state: {
																apiKey: item.apiKey,
																userApiId:
																	_userApiId,
															},
														});
													}}
												>
													{item.url}
												</Link>
											}
											description="Click the link to the editor"
										/>
									</List.Item>
								)}
							/>{" "}
						</Skeleton>
					</Col>
					<Col xs={24} xl={12} className="dashboard_col" span={6} />
				</Row>
			</Layout>
		</>
	);
};
