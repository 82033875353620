import React, { useContext, useState } from "react";
import _ from "lodash";
import { Layout, Row, Col, Card, Form } from "antd";
import "./Register.less";
import "antd-country-phone-input/dist/index.css";
import { BackHeader, FormButton } from "../../utils/components";
import Title from "antd/lib/typography/Title";
import { FirebaseServices } from "../../utils/firebaseServices";
import { AuthContext } from "../../utils/AuthContext";
import { RoutePath } from "../../utils/routePath";
import { useHistory } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import mixpanel from "mixpanel-browser";

export const EmailVerification: React.FC<any> = () => {
    let history = useHistory();

    const time = new Date();
    time.setSeconds(time.getSeconds() + 60);

    const {
        seconds,
        start,
        restart,
    } = useTimer({
        autoStart: false,
        expiryTimestamp: time,
        onExpire: () => {
            // Show the button on expiring time
            setBtn(true);
            // Reset the time to 60 seconds after the expiring time
            restart(time, false);
        },
    });

    // Get current sign in User
    const user = useContext(AuthContext);

    // Is loading flag
    const [isLoading, setLoading] = useState(false);

    const [isBtnEnabled, setBtn] = useState(true);

    const onFinish = async (values: any) => {
        setLoading(true);
        mixpanel.track("Email_Verification_Execute_Email_Verification_Click");


        // If user not found, then proceed to log in page
        if (user) {
            //If the meail has verified, proceed to home page
            if (user?.emailVerified) history.replace(RoutePath.home);
            await FirebaseServices.sendEmailVerification(user);
            alert("Email verification has sent");
            // Start the timer
            setBtn(false);
            start();
        } else {
            history.replace(RoutePath.login);
        }

        setLoading(false);
    };

    return (
        <Layout className="layout" style={{ overflow: "auto" }}>
            <div className="navbar" style={{ backgroundColor: "#fff" }}>
                <BackHeader title="Email Verification" />
            </div>
            <Layout className="emailverification_content" style={{ backgroundColor: "white" }}>
                <Row className="register_form_row">
                    <Col
                        xs={24}
                        xl={8}
                        className="register_form_col"
                        span={8}
                    />
                    <Col xs={24} xl={8} className="register_form_col" span={8}>
                        <Card>
                            <Form
                                name="register"
                                className="register_form"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                            >
                                <Title level={4}>
                                    Email verification has sent to your mailbox.
                                </Title>
                                <p>
                                    If not found, please check your junk mail.
                                </p>
                                {isBtnEnabled ? (
                                    <Form.Item>
                                        <FormButton
                                            title="Send Email Verification"
                                            isLoading={isLoading}
                                        />
                                    </Form.Item>
                                ) : (
                                    <p>
                                        {" "}
                                        Please send again in {seconds} second if
                                        not found.
                                    </p>
                                )}
                            </Form>
                        </Card>
                    </Col>
                    <Col
                        xs={24}
                        xl={8}
                        className="register_form_col"
                        span={8}
                    />
                </Row>
            </Layout>
        </Layout>
    );
};
