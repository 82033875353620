import React, { ReactElement, useState } from "react";
import _ from "lodash";
import { Layout, Row, Col, Form, Card } from "antd";
import "./ResetPassword.less";
import { BackHeader, EmailField, FormButton } from "../../utils/components";
import { FirebaseServices } from "../../utils/firebaseServices";
import { useHistory } from "react-router-dom";
import { RoutePath } from "../../utils/routePath";
import { LocalStorage } from "../../utils/localStorage";
import mixpanel from "mixpanel-browser";

export const ResetPassword: React.FC<any> = () => {
	let history = useHistory();

	const [isLoading, setLoading] = useState(false);

	const onFinish = async (values: any) => {
		mixpanel.track("Reset_Password_Excecute_Reset_Password_Click");

		const email = await LocalStorage.getEmail();
		setLoading(true);
		let firebaseResult = await FirebaseServices.resetPassword(email ?? "");

		if (firebaseResult.isSuccess) {
			// Go to login page
			mixpanel.track("Reset_Password_Excecute_Reset_Password_Click", {
				firebaseResult: firebaseResult.message,
			});

			alert(`Sent password reset email to ${email}`);
			history.replace(RoutePath.home);
			setLoading(false);
		} else {
			mixpanel.track("Reset_Password_Excecute_Reset_Password_Click", {
				firebaseResult: firebaseResult.message,
			});

			alert(firebaseResult.message);
			setLoading(false);
		}
	};

	return (
		<Layout className="layout" style={{ overflow: "auto" }}>
			<div className="navbar" style={{ backgroundColor: "#fff" }}>
				<BackHeader title="Reset Password" />
			</div>
			<Layout
				className="reset_password_content"
				style={{ backgroundColor: "white" }}
			>
				<Row className="reset_password_row">
					<Col
						xs={24}
						xl={8}
						className="reset_password_col"
						span={8}
					/>
					<Col xs={24} xl={8} className="reset_password_col" span={8}>
						<Card>
							<Form
								name="reset_password"
								className="reset_password_form"
								initialValues={{ remember: true }}
								onFinish={onFinish}
							>
								<Form.Item>
									<FormButton
										title="Send Password Reset Email"
										isLoading={isLoading}
									/>
								</Form.Item>
							</Form>
						</Card>
					</Col>
					<Col
						xs={24}
						xl={8}
						className="reset_password_col"
						span={8}
					/>
				</Row>
			</Layout>
		</Layout>
	);
};
