import React from "react";
import { Bar, Chart } from "react-chartjs-2";
import { ChartColor } from "./ChartColor";
import 'chart.js/auto';


export interface DataProps {
	data: {
		key: string;
		title: string;
		main_chart_type: any;
		dataSet: Array<{
			graph_type: any;
			label: string;
			metrics: any;
		}>;
		dimension: Array<{ name: string; data: [] }>;
		data_grid: any;
		// The graph type is same as the one in dataset object
		chosen_schema: Array<{
			name: string;
			operation: string;
			graph_type: string;
		}>;
		
	};
}

export const MyBar: React.FC<DataProps> = ({ data }) => {
	const config = (
		item: { graph_type: any; label: string; metrics: any },
		index: number
	) => {
		switch (item.graph_type) {
			case "bar":
				return {
					fill: false,
					lineTension: 0.5,
					backgroundColor: ChartColor.colorOptions[index],
					borderColor: "white",
					borderWidth: 2,
				};

			case "line":
				return {
					fill: false,
					lineTension: 0.5,
					borderColor: ChartColor.colorOptions[index],
					borderWidth: 2,
				};
			case "pie":
				return {
					fill: false,
					lineTension: 0.5,
					backgroundColor: ChartColor.colorOptions,
					borderColor: "white",
					borderWidth: 1,
				};

			default:
				return {
					fill: false,
					lineTension: 0.5,
					backgroundColor: ChartColor.colorOptions[index],
					borderColor: "white",
				};
		}
	};

	return (
		<Chart
			type={data.main_chart_type}
			redraw={true}
			data={{
				labels: data.dimension[0]?.data,
				datasets: data.dataSet.map((item, index) => {
					return {
						...config(item, index),
						label: item.label,
						data: item.metrics,
						type: item.graph_type,
					};
				}),
			}}
			options={{
				plugins: {
					legend: {
						display: true,
						position: "right",
					},
					title: {
						display:
							data.title === undefined || data.title === ""
								? false
								: true,
						text: data.title,
					},
				},
				maintainAspectRatio: false,
				responsive: true,
			}}
		/>
	);
};
