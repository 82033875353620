import {AES, enc} from "crypto-js";

export class Encryption {
    /*----- Encruption using Advanced Encryption Standard (AES) ------*/
    static encrypt = (secretPhrase: string) => {
        // Encrypt the encrypted string
        return AES.encrypt(secretPhrase, "Secret Phrase").toString();
    };

    static decrypt = (encryptedPhrase: string) => {
        // Decrypt the encrypted string
        return AES.decrypt(encryptedPhrase, "Secret Phrase").toString(enc.Utf8);
    };
}
