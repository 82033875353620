import React, { ReactElement, useEffect, useState } from "react";
import _ from "lodash";
import { Layout, message, Spin } from "antd";
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { SizeMe, withSize } from "react-sizeme";
import { DataProps, MyBar } from "../chartManager/BarChart";
import { Content } from "antd/lib/layout/layout";
import { UrlRepo } from "../../services/api/repositories/url_repo";
import { ConfigParam } from "../../services/api/models/url_model";

// Resize the Grid Layout according to the parent widget
const withSizeHOC = withSize();
const ReactGridLayout = withSizeHOC(RGL);

interface GraphPreviewProps {
	apiKey: string;
}

export const GraphPreview: React.FC<GraphPreviewProps> = ({ apiKey }) => {
	// State of managing graph properties
	const [_graphValues, setGraphValues] = useState<Array<DataProps["data"]>>(
		[]
	);

	const [_isEditorLoading, setEditorLoading] = useState<boolean>(true);

	let urlRepo = new UrlRepo();

	useEffect(() => {
		async function initState() {
			let graphGetResult = await urlRepo.getGraphsByApiKey({
				apiKey: apiKey,
			});
			if (graphGetResult.isSuccess) {
				graphGetResult.data.forEach((item: any) => {
					item["data_grid"]["static"] = true;
				});
				setGraphValues(graphGetResult.data);
			} else {
				message.error(graphGetResult.message);
			}
			setEditorLoading(false);
		}
		initState();
	}, []);

	return (
		<div>
			{_isEditorLoading ? (
						<Spin></Spin>
					) : (
						<SizeMe>
							{({ size }) => (
								<ReactGridLayout
									className="grid-layout"
									width={size.width as number}
									rowHeight={(size.width as number) / 60}
									cols={30}
								>
									{_graphValues.map((item, index) => {
										return (
											<div
												key={item.key}
												data-grid={item.data_grid}
												style={{
													width: "auto",
													border: "1px solid #D3D3D3",
													padding:
														"10px 10px 10px 10px",
													height: "100px", // To enable the chart responsive in height
												}}
											>
												<MyBar
													data={{
														key: item.key,
														main_chart_type:
															item.main_chart_type,
														title: item.title,
														dataSet: item.dataSet,
														dimension:
															item.dimension,
														data_grid:
															item.data_grid,
														chosen_schema:
															item.chosen_schema,
													}}
												/>
											</div>
										);
									})}
								</ReactGridLayout>
							)}
						</SizeMe>
					)}
		</div>
	);
};
