import React, { ReactElement, useEffect, useState } from "react";
import _ from "lodash";
import {
	Tooltip,
	Button,
	Layout,
	Menu,
	Typography,
	PageHeader,
	message,
	Dropdown,
	Spin,
	Modal,
} from "antd";
import { Button as ChakraButton } from "@chakra-ui/react";
import {
	BarChartOutlined,
	CopyOutlined,
	EditTwoTone,
	EyeTwoTone,
	HomeTwoTone,
	LeftCircleTwoTone,
	LeftSquareOutlined,
	LineChartOutlined,
	MenuOutlined,
	PieChartOutlined,
	PlusCircleTwoTone,
	RightCircleTwoTone,
	SaveTwoTone,
} from "@ant-design/icons";
import "./GraphEditor.less";
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { SizeMe, withSize } from "react-sizeme";
import { BackHeader } from "../../utils/components";
import { PageSider } from "../sider/PageSider";
import faker from "@faker-js/faker";
import { UrlRepo } from "../../services/api/repositories/url_repo";
import { ConfigParam, SchemaField } from "../../services/api/models/url_model";
import { DataProps, MyBar } from "../chartManager/BarChart";
import { v4 as uuidv4 } from "uuid";
import { Content } from "antd/lib/layout/layout";
import { useHistory, useLocation } from "react-router-dom";
import { LocalStorage } from "../../utils/localStorage";
import { RoutePath } from "../../utils/routePath";
import { GraphPreview } from "../graph_display/GraphPreview";
import mixpanel from "mixpanel-browser";

// Resize the Grid Layout according to the parent widget
const withSizeHOC = withSize();
const ReactGridLayout = withSizeHOC(RGL);

export const GraphEditor: React.FC<any> = () => {
	let location = useLocation();

	let history = useHistory();

	// Update the visibility of sider
	const [_siderVisibility, setSiderVisibility] = useState<string>("block");

	// Handle the data retrieved
	const [_schemaField, setSchemaField] = useState<Array<SchemaField>>([]);

	// State of managing graph properties
	const [_graphValues, setGraphValues] = useState<Array<DataProps["data"]>>(
		[]
	);

	const [_configParam, setConfigParam] = useState<Array<ConfigParam>>([]);

	const [_isSaving, setSaving] = useState<boolean>(false);

	const [_isPreviewVisible, setPreviewVisible] = useState<boolean>(false);

	const [_layouts, setlayouts] = useState<Array<any>>([]);

	const [_isEditorLoading, setEditorLoading] = useState<boolean>(true);

	// Get the selected graph value from graph value array
	const [_selectedGraph, setSelectedGraph] = useState<DataProps["data"]>();

	const locationState: any = location.state;

	const dropdownMenu = (
		<Menu onClick={handleDropDown}>
			<Menu.Item key="bar" icon={<BarChartOutlined />}>
				Bar Chart
			</Menu.Item>
			<Menu.Item key="line" icon={<LineChartOutlined />}>
				Line Graph
			</Menu.Item>
			<Menu.Item key="pie" icon={<PieChartOutlined />}>
				Pie Chart
			</Menu.Item>
		</Menu>
	);

	let urlRepo = new UrlRepo();

	function onRemoveItemGridContent(key: string) {
		// Filter is used to get all the graphs except the graph with the key value mentioned.
		setGraphValues(_graphValues.filter((item) => item.key !== key));
	}

	// Add new graph
	function handleDropDown(e: any) {
		mixpanel.track("Graph_Editor_Execute_Select_Graph_Type_Click", {
			SelectedType: e.key,
		});

		let key = uuidv4();
		setGraphValues([
			..._graphValues,
			{
				key: key,
				main_chart_type: e.key,
				title: "",
				dataSet: [],
				dimension: [],
				data_grid: {
					i: "a",
					x: 0,
					y: 0,
					w: 10,
					h: 5,
					minW: 3,
					minH: 3,
				},
				chosen_schema: [],
			},
		]);

		setSelectedGraph({
			key: key,
			main_chart_type: e.key,
			title: "",
			dataSet: [],
			dimension: [],
			data_grid: {
				i: "a",
				x: 0,
				y: 0,
				w: 10,
				h: 5,
				minW: 3,
				minH: 3,
			},
			chosen_schema: [],
		});
	}

	const saveGraph = async (layouts: any) => {
		setSaving(true);
		mixpanel.track("Graph_Editor_Execute_Save_Graph_Click");

		let apiResult = await urlRepo.saveGraph({
			userApiId: locationState.userApiId,
			apiKey: locationState.apiKey,
			layouts: layouts,
			graphs: _graphValues.map((item, index) => {
				return {
					// Temporary hardcode the array on dimensiion
					label_name: item.title,
					dimension_array:
						item.dimension.length > 0
							? [item.dimension[0]["name"]]
							: [],
					metrics_operation_array:
						item.chosen_schema.length > 0 ? item.chosen_schema : [],
					data_grid: layouts[index],
				};
			}),
		});
		setSaving(false);

		return apiResult.isSuccess;
	};

	// onKeyDown handler function
	const keyDownHandler = async (
		event: React.KeyboardEvent<HTMLDivElement>
	) => {
		if (event.code === "KeyS" && event.ctrlKey) {
			if (await saveGraph(_layouts)) {
				mixpanel.track("Graph_Editor_Execute_Save_Graph_Key_Down");

				message.success("Save successfuly");
			} else {
				message.error("Save failure");
			}
		}
	};

	async function onLayoutChange(e: Array<any>) {
		let layouts = e;
		setlayouts(layouts);
	}

	useEffect(() => {
		// Confirmation dialog popup if any changes made in page
		const unloadCallback = (event: any) => {
			event.preventDefault();
			event.returnValue = "";
			return "";
		};

		window.addEventListener("beforeunload", unloadCallback);
		return () => window.removeEventListener("beforeunload", unloadCallback);
	}, []);

	useEffect(() => {
		async function initState() {
			let apiResult = await urlRepo.getSchemaByApiKey({
				apiKey: locationState.apiKey,
			});

			if (apiResult.isSuccess) {
				setSchemaField(apiResult.data);
				let graphGetResult = await urlRepo.getGraphsByApiKey({
					apiKey: locationState.apiKey,
				});
				if (graphGetResult.isSuccess) {
					setSelectedGraph(
						graphGetResult.data[graphGetResult.data.length - 1]
					);
					setGraphValues(graphGetResult.data);
				}
			}
			setEditorLoading(false);
		}

		initState();
	}, []);

	return (
		<Layout className="layout">
			<div className="navbar" style={{ backgroundColor: "#fff" }}>
				<PageHeader
					ghost={false}
					onBack={() => window.history.back()}
					title="Graph Editor"
					extra={[
						<Tooltip placement="topLeft" title="Save">
							<ChakraButton
								isLoading={_isSaving}
								loadingText="Saving"
								colorScheme="teal"
								variant="outline"
								onClick={async () => {
									if (await saveGraph(_layouts)) {
										message.success("Save successfuly");
									} else {
										message.error("Save failure");
									}
								}}
							>
								<SaveTwoTone twoToneColor="#52c41a" />
							</ChakraButton>
						</Tooltip>,
						<Tooltip placement="topLeft" title="Dashboard">
							<Button
								icon={<HomeTwoTone twoToneColor="#c175ff" />}
								shape="round"
								size="large"
								onClick={() => {
									mixpanel.track(
										"Graph_Editor_View_Dashboard_Click"
									);
									history.push(RoutePath.dashboard);
								}}
							></Button>
						</Tooltip>,
						<Tooltip placement="topLeft" title="Edit Url">
							<Button
								icon={<EditTwoTone />}
								shape="round"
								size="large"
								onClick={() => {
									mixpanel.track(
										"Graph_Editor_View_Edit_Url_Click"
									);
									history.push({
										pathname: RoutePath.fill_url,
										state: { apiKey: locationState.apiKey },
									});
								}}
							></Button>
						</Tooltip>,

						<Tooltip placement="topLeft" title="Preview">
							<Button
								icon={<EyeTwoTone twoToneColor="#FFA500" />}
								shape="round"
								size="large"
								onClick={async () => {
									mixpanel.track(
										"Graph_Editor_View_Graph_Preview_Click"
									);
									await saveGraph(_layouts);
									let configParamResult =
										await urlRepo.getConfigParamByApiKey({
											apiKey: locationState.apiKey,
										});

									if (configParamResult.isSuccess) {
										setConfigParam(configParamResult.data);
									}
									setPreviewVisible(true);
								}}
							></Button>{" "}
						</Tooltip>,
						<Dropdown overlay={dropdownMenu} placement="bottomLeft">
							<Button
								icon={
									<PlusCircleTwoTone twoToneColor="#eb2f96" />
								}
								shape="round"
								size="large"
							></Button>
						</Dropdown>,
						<Button
							icon={
								_siderVisibility === "block" ? (
									<RightCircleTwoTone twoToneColor="#4654A3" />
								) : (
									<LeftCircleTwoTone twoToneColor="#4654A3" />
								)
							}
							shape="round"
							size="large"
							onClick={() => {
								mixpanel.track(
									"Graph_Editor_Execute_Sider_Visibility_Click"
								);
								setSiderVisibility(
									_siderVisibility === "block"
										? "none"
										: "block"
								);
							}}
						></Button>,
					]}
				/>
			</div>
			<Layout style={{ overflow: "hidden" }} onKeyPress={keyDownHandler}>
				<Layout style={{ overflow: "hidden", padding: "0 24px 24px" }}>
					<Layout style={{ overflow: "auto", minHeight: "76vh" }}>
						<Content
							className="site-layout-background"
							style={{
								padding: 0,
								margin: 0,
								alignItems: "center",
							}}
						>
							<Modal
								title="Preview"
								centered
								visible={_isPreviewVisible}
								onOk={() => setPreviewVisible(false)}
								onCancel={() => setPreviewVisible(false)}
								width={1300}
								destroyOnClose={true}
								footer={[
									<Tooltip
										placement="topLeft"
										title="Copy graph iframe"
									>
										<Button
											shape="round"
											icon={<CopyOutlined />}
											size={"large"}
											onClick={() => {
												mixpanel.track(
													"Graph_Editor_View_Copy_Url_Click"
												);
												// Merge all config params
												let mergedConfigParam = "";
												if (_configParam.length > 0) {
													_configParam?.forEach(
														(item) => {
															mergedConfigParam = `${mergedConfigParam}&${item.name}=${item.defaultValue}`;
														}
													);
												}
												navigator.clipboard.writeText(
													`<iframe src='https://www.codagence.com/graph_display?apiKey=${locationState.apiKey}${mergedConfigParam}' style='height:200px;width:300px;' title='Iframe Example'></iframe>` ??
														"Empty Url"
												);
												message.success(
													"iframe is copied"
												);
											}}
										/>
									</Tooltip>,
								]}
							>
								<GraphPreview
									apiKey={locationState.apiKey}
								></GraphPreview>
							</Modal>
							{_isEditorLoading ? (
								<Spin></Spin>
							) : (
								<SizeMe>
									{({ size }) => (
										<ReactGridLayout
											className="grid-layout"
											width={size.width as number}
											rowHeight={
												(size.width as number) / 60
											}
											cols={30}
											onLayoutChange={onLayoutChange}
										>
											{_graphValues.map((item, index) => {
												return (
													<div
														key={item.key}
														data-grid={
															item.data_grid
														}
														style={{
															width: "auto",
															border:
																_selectedGraph!
																	.key ===
																item.key
																	? "1px solid #4654A3"
																	: "1px solid #D3D3D3",
															padding:
																"10px 10px 10px 10px",
															height: "100px", // To enable the chart responsive in height
														}}
														onClick={() => {
															mixpanel.track(
																"Graph_Editor_Execute_Select_Graph_Click"
															);
															setSelectedGraph(
																_graphValues[
																	index
																]
															);
														}}
													>
														<MyBar
															data={{
																key: item.key,
																main_chart_type:
																	item.main_chart_type,
																title: item.title,
																dataSet:
																	item.dataSet,
																dimension:
																	item.dimension,
																data_grid:
																	item.data_grid,
																chosen_schema:
																	item.chosen_schema,
															}}
														/>

														<span
															className="remove"
															style={{
																position:
																	"absolute",
																right: "2px",
																top: 0,
																cursor: "pointer",
															}}
															onClick={() =>
																onRemoveItemGridContent(
																	item.key
																)
															}
														>
															&#10006;
														</span>
													</div>
												);
											})}
										</ReactGridLayout>
									)}
								</SizeMe>
							)}
						</Content>
					</Layout>
				</Layout>
				<PageSider
					siderVisibility={_siderVisibility}
					toggleCollapsed={() =>
						setSiderVisibility(
							_siderVisibility === "block" ? "none" : "block"
						)
					}
					setGraphValues={setGraphValues}
					graphValues={_graphValues}
					selectedGraph={_selectedGraph}
					dataRows={_schemaField}
					schemaField={_schemaField}
					userApiId={locationState.userApiId}
					apiKey={locationState.apiKey}
				/>
			</Layout>
		</Layout>
	);
};
