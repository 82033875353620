import { url } from "inspector";
import { Networking } from "../../networking";
import { Response } from "../../response";
import {
	ConfigParam,
	DataRows,
	GetConfigParamResponse,
	GetDataRowsResponse,
	GetSchemaFieldResponse,
	GetUserUrlResponse,
	Graphs,
	SchemaField,
	UserUrl,
} from "../models/url_model";

export class UrlRepo {
	networking = new Networking();

	async getAllUserUrlByUserId(value?: { userId: string }): Promise<Response> {
		const path = `userId=${value?.userId}`;
		let response = await this.networking.getData(
			`get_all_url_by_user_id?${path}`
		);
		// If data is retrieved and the data is not empty, then return
		if (
			response.isSuccess &&
			response.data !== null &&
			response.data !== ""
		) {
			let getUserUrlResponse = new GetUserUrlResponse().fromJson(
				response.data
			);
			return new Response(true, response.message, getUserUrlResponse);

			// If data is retrieved and the data is empty, then return
		} else if (
			response.isSuccess &&
			response.message === "No records found"
		) {
			return new Response(true, "No records found", "");

			// If http method is timeout or being halt, then return
		} else if (!response.isSuccess) {
			return new Response(false, response.message, "");
		} else {
			return new Response(false, response.message, response.data);
		}
	}

	async getUrlById(value?: { urlId: string }): Promise<Response> {
		const path = `urlId=${value?.urlId}`;
		let response = await this.networking.getData(`get_url_by_id?${path}`);
		// If data is retrieved and the data is not empty, then return
		if (
			response.isSuccess &&
			response.data !== null &&
			response.data !== ""
		) {
			let getUserUrlResponse = new GetUserUrlResponse().fromJson(
				response.data
			);
			return new Response(true, response.message, getUserUrlResponse);

			// If data is retrieved and the data is empty, then return
		} else if (
			response.isSuccess &&
			response.message === "No records found"
		) {
			return new Response(true, "No records found", "");

			// If http method is timeout or being halt, then return
		} else if (!response.isSuccess) {
			return new Response(false, response.message, "");
		} else {
			return new Response(false, response.message, response.data);
		}
	}

	async getUrlByApiKey(value?: { apiKey: string }): Promise<Response> {
		const path = `apiKey=${value?.apiKey}`;
		let response = await this.networking.getData(
			`get_url_by_api_key?${path}`
		);
		// If data is retrieved and the data is not empty, then return
		if (
			response.isSuccess &&
			response.data !== null &&
			response.data !== ""
		) {
			let getUserUrlResponse = new GetUserUrlResponse().fromJson(
				response.data
			);
			return new Response(true, response.message, getUserUrlResponse);

			// If data is retrieved and the data is empty, then return
		} else if (
			response.isSuccess &&
			response.message === "No records found"
		) {
			return new Response(true, "No records found", "");

			// If http method is timeout or being halt, then return
		} else if (!response.isSuccess) {
			return new Response(false, response.message, "");
		} else {
			return new Response(false, response.message, response.data);
		}
	}

	async saveUrl(props: {
		urlId?: string;
		userId?: string;
		apiKey?: string;
		url?: string;
		schema?: Array<SchemaField>;
		configParam?: Array<ConfigParam>;
	}): Promise<Response> {
		// Initialize the save user model
		const save_url = new UserUrl({
			userId: props.userId,
			apiKey: props.apiKey,
			url: props.url,
			schema: props.schema,
			configParam: props.configParam,
		});

		// Object to Map, then to JSON
		const body = Object.fromEntries(save_url.toJson());

		let headers = {
			"Content-Type": "application/json",
		};

		let response = await this.networking.postData(
			"save_url", // API choice is depends on the usage
			body,
			headers
		);

		// If data is retrieved and the data is not empty, then return
		if (
			response.isSuccess &&
			response.data !== null &&
			response.data !== ""
		) {
			return new Response(true, response.message, response.data);

			// If data is retrieved and the data is empty, then return
		} else if (
			response.isSuccess &&
			response.message === "No records found"
		) {
			return new Response(true, "No records found", "");

			// If http method is timeout or being halt, then return
		} else if (!response.isSuccess) {
			return new Response(false, response.message, "");
		} else {
			return new Response(false, response.message, response.data);
		}
	}

	async deleteUrl(props: { urlId?: string }): Promise<Response> {
		// Initialize the save user model
		const delete_url = new UserUrl({
			urlId: props.urlId,
		});

		// Object to Map, then to JSON
		const body = Object.fromEntries(delete_url.toJson());

		let response = await this.networking.deleteData(
			"delete_url", // API choice is depends on the usage
			body
		);

		// If data is retrieved and the data is not empty, then return
		if (
			response.isSuccess &&
			response.data !== null &&
			response.data !== ""
		) {
			return new Response(true, response.message, response.data);

			// If data is retrieved and the data is empty, then return
		} else if (
			response.isSuccess &&
			response.message === "No records found"
		) {
			return new Response(true, "No records found", "");

			// If http method is timeout or being halt, then return
		} else if (!response.isSuccess) {
			return new Response(false, response.message, "");
		} else {
			return new Response(false, response.message, response.data);
		}
	}

	async validateUrl(props: { url?: string }): Promise<Response> {
		// Initialize the save user model
		const validate_url = new SchemaField({
			url: props.url,
		});

		// Object to Map, then to JSON
		const body = Object.fromEntries(validate_url.toJson());

		let headers = {
			"Content-Type": "application/json",
		};

		let response = await this.networking.postData(
			"validate_url", // API choice is depends on the usage
			body,
			headers
		);

		// If data is retrieved and the data is not empty, then return
		if (
			response.isSuccess &&
			response.data !== null &&
			response.data !== ""
		) {
			let getSchemaFieldResponse = new GetSchemaFieldResponse().fromJson(
				response.data
			);
			return new Response(true, response.message, getSchemaFieldResponse);

			// If data is retrieved and the data is empty, then return
		} else if (
			response.isSuccess &&
			response.message === "No records found"
		) {
			return new Response(true, "No records found", "");

			// If http method is timeout or being halt, then return
		} else if (!response.isSuccess) {
			return new Response(false, response.message, "");
		} else {
			return new Response(false, response.message, response.data);
		}
	}

	async getSchemaByApiKey(value?: { apiKey: string }): Promise<Response> {
		const path = `apiKey=${value?.apiKey}`;
		let response = await this.networking.getData(
			`get_schema_field?${path}`
		);
		// If data is retrieved and the data is not empty, then return
		if (
			response.isSuccess &&
			response.data !== null &&
			response.data !== ""
		) {
			let getSchemaFieldResponse = new GetSchemaFieldResponse().fromJson(
				response.data
			);
			return new Response(true, response.message, getSchemaFieldResponse);

			// If data is retrieved and the data is empty, then return
		} else if (
			response.isSuccess &&
			response.message === "No records found"
		) {
			return new Response(true, "No records found", "");

			// If http method is timeout or being halt, then return
		} else if (!response.isSuccess) {
			return new Response(false, response.message, "");
		} else {
			return new Response(false, response.message, response.data);
		}
	}

	async getConfigParamByApiKey(value?: {
		apiKey: string;
	}): Promise<Response> {
		const path = `apiKey=${value?.apiKey}`;
		let response = await this.networking.getData(
			`get_config_param_by_api_key?${path}`
		);
		// If data is retrieved and the data is not empty, then return
		if (
			response.isSuccess &&
			response.data !== null &&
			response.data !== ""
		) {
			let getConfigParamResponse = new GetConfigParamResponse().fromJson(
				response.data
			);
			return new Response(true, response.message, getConfigParamResponse);

			// If data is retrieved and the data is empty, then return
		} else if (
			response.isSuccess &&
			response.message === "No records found"
		) {
			return new Response(true, "No records found", "");

			// If http method is timeout or being halt, then return
		} else if (!response.isSuccess) {
			return new Response(false, response.message, "");
		} else {
			return new Response(false, response.message, response.data);
		}
	}

	async getDataRowsByApiKey(value?: {
		userApiId: string;
		apiKey: string;
		dimensionArray?: Array<string>;
		metricsArray?: Array<string>;
	}): Promise<Response> {
		const path = `userApiId=${value?.userApiId}&apiKey=${
			value?.apiKey
		}&dimensionArray=${JSON.stringify(
			value?.dimensionArray
		)}&metricsArray=${JSON.stringify(value?.metricsArray)}`;
		let response = await this.networking.getData(`generate_data?${path}`);
		// If data is retrieved and the data is not empty, then return
		if (
			response.isSuccess &&
			response.data !== null &&
			response.data !== ""
		) {
			let getDataRowsByApiKey = new GetDataRowsResponse().fromJson(
				response.data
			);
			return new Response(true, response.message, getDataRowsByApiKey);

			// If data is retrieved and the data is empty, then return
		} else if (
			response.isSuccess &&
			response.message === "No records found"
		) {
			return new Response(true, "No records found", "");

			// If http method is timeout or being halt, then return
		} else if (!response.isSuccess) {
			return new Response(false, response.message, "");
		} else {
			return new Response(false, response.message, response.data);
		}
	}

	async generateDataResponse(props: {
		userApiId?: string;
		apiKey?: string;
		dimensionArray?: Array<string>;
		metricsArray?: Array<string>;
		metricsOperation?: Array<string>;
	}): Promise<Response> {
		// Initialize the save user model
		const generate_data = new DataRows({
			userApiId: props.userApiId,
			apiKey: props.apiKey,
			dimensionArray: props.dimensionArray,
			metricsArray: props.metricsArray,
			metricsOperation: props.metricsOperation,
		});

		// Object to Map, then to JSON
		const body = Object.fromEntries(generate_data.toJson());

		let headers = {
			"Content-Type": "application/json",
		};

		let response = await this.networking.postData(
			"generate_data", // API choice is depends on the usage
			body,
			headers
		);

		// If data is retrieved and the data is not empty, then return
		if (
			response.isSuccess &&
			response.data !== null &&
			response.data !== ""
		) {
			let getSchemaFieldResponse = new GetDataRowsResponse().fromJson(
				response.data
			);
			return new Response(true, response.message, getSchemaFieldResponse);

			// If data is retrieved and the data is empty, then return
		} else if (
			response.isSuccess &&
			response.message === "No records found"
		) {
			return new Response(true, "No records found", "");

			// If http method is timeout or being halt, then return
		} else if (!response.isSuccess) {
			return new Response(false, response.message, "");
		} else {
			return new Response(false, response.message, response.data);
		}
	}

	async saveGraph(props: {
		userApiId: string;
		apiKey: string;
		layouts: Array<any>;
		graphs: Array<{
			dimension_array: Array<any>;
			metrics_operation_array: Array<any>;
			data_grid: Array<any>;
		}>;
	}): Promise<Response> {
		// Initialize the save user model
		const save_graph = new Graphs({
			userApiId: props.userApiId,
			apiKey: props.apiKey,
			layouts: props.layouts,
			graphs: props.graphs,
		});

		// Object to Map, then to JSON
		const body = Object.fromEntries(save_graph.toJson());

		let headers = {
			"Content-Type": "application/json",
		};

		let response = await this.networking.postData(
			"save_graph", // API choice is depends on the usage
			body,
			headers
		);

		// If data is retrieved and the data is not empty, then return
		if (
			response.isSuccess &&
			response.data !== null &&
			response.data !== ""
		) {
			return new Response(true, response.message, true);

			// If data is retrieved and the data is empty, then return
		} else if (
			response.isSuccess &&
			response.message === "No records found"
		) {
			return new Response(true, "No records found", "");

			// If http method is timeout or being halt, then return
		} else if (!response.isSuccess) {
			return new Response(false, response.message, "");
		} else {
			return new Response(false, response.message, response.data);
		}
	}

	async getGraphsByApiKey(value?: { apiKey: string }): Promise<Response> {
		const path = `apiKey=${value?.apiKey}`;
		let response = await this.networking.getData(`get_graph_by_api_key?${path}`);
		// If data is retrieved and the data is not empty, then return
		if (
			response.isSuccess &&
			response.data !== null &&
			response.data !== ""
		) {
			let getDataRowsByApiKey = new GetDataRowsResponse().fromJson(
				response.data
			);
			return new Response(true, response.message, getDataRowsByApiKey);

			// If data is retrieved and the data is empty, then return
		} else if (
			response.isSuccess &&
			response.message === "No records found"
		) {
			return new Response(true, "No records found", "");

			// If http method is timeout or being halt, then return
		} else if (!response.isSuccess) {
			return new Response(false, response.message, "");
		} else {
			return new Response(false, response.message, response.data);
		}
	}

	async getGraphsWithParamByApiKey(path :string): Promise<Response> {
		let response = await this.networking.getData(`get_graph_with_param_by_api_key?${path}`);
		// If data is retrieved and the data is not empty, then return
		if (
			response.isSuccess &&
			response.data !== null &&
			response.data !== ""
		) {
			let getDataRowsByApiKey = new GetDataRowsResponse().fromJson(
				response.data
			);
			return new Response(true, response.message, getDataRowsByApiKey);

			// If data is retrieved and the data is empty, then return
		} else if (
			response.isSuccess &&
			response.message === "No records found"
		) {
			return new Response(true, "No records found", "");

			// If http method is timeout or being halt, then return
		} else if (!response.isSuccess) {
			return new Response(false, response.message, "");
		} else {
			return new Response(false, response.message, response.data);
		}
	}
}
