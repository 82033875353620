import React, { ReactElement, useEffect, useState } from "react";
import _ from "lodash";
import {
	Layout,
	Row,
	Col,
	Form,
	Card,
	message,
	Input,
	Space,
	Button,
	Modal,
	Select,
	Tooltip,
} from "antd";
import "./Url.less";
import { BackHeader, EmailField, FormButton } from "../../utils/components";
import { FirebaseServices } from "../../utils/firebaseServices";
import { useHistory, useLocation } from "react-router-dom";
import { RoutePath } from "../../utils/routePath";
import { UrlRepo } from "../../services/api/repositories/url_repo";
import { LocalStorage } from "../../utils/localStorage";
import { v4 as uuidv4 } from "uuid";
import Title from "antd/lib/typography/Title";
import { url } from "inspector";
import {
	CopyOutlined,
	InfoCircleOutlined,
	MinusCircleOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import { stringify } from "querystring";
import { ObjectType } from "typescript";
import { SchemaField, UserUrl } from "../../services/api/models/url_model";
import mixpanel from "mixpanel-browser";

const { Option } = Select;

export const FillField: React.FC<any> = () => {
	let history = useHistory();

	let location = useLocation();

	let urlRepo = new UrlRepo();

	const [form] = Form.useForm();

	const [isLoading, setLoading] = useState(false);

	const [schema, setSchema] = useState<
		Array<{
			name: any;
			label_name: any;
			data_type: any;
		}>
	>([]);

	const [apiStatus, setAPIStatus] = useState<boolean>(false);

	const locationState: any = location.state;

	const onFinish = async (values: any) => {
		mixpanel.track("Fill_Field_Excecute_Add_Fields_Click");
		// Handle if API given error
		if (apiStatus) {
			let userId = await LocalStorage.getUserID();
			setLoading(true);
			let apiResult = await urlRepo.saveUrl({
				url: locationState.url,
				userId: userId ?? "",
				apiKey: locationState.apiKey,
				schema: values["schemaList"],
				configParam: locationState.configParam,
			});

			if (apiResult.isSuccess) {
				mixpanel.track("Fill_Field_Excecute_Add_Fields_Click", {
					apiResult: apiResult.message,
				});
				message.success("API added successful");

				history.push({
					pathname: RoutePath.graph_editor,
					state: {
						apiKey: locationState.apiKey,
						userApiId: locationState.userApiId,
					},
				});
			} else {
				mixpanel.track("Fill_Field_Excecute_Add_Fields_Click", {
					apiResult: apiResult.message,
				});
				message.error(apiResult.message);
			}
			setLoading(false);
		} else {
			message.error(
				"The response of your API is in invalid format or API errors"
			);
		}
	};
	async function initState() {
		let apiResult = await urlRepo.validateUrl({
			url: locationState.url!,
		});
		if (apiResult.isSuccess) {
			message.success("Your API is valid");

			apiResult.data?.forEach((item: SchemaField) => {
				setSchema((prevState) => {
					return [
						...prevState,
						{
							label_name: item.labelName ?? "",
							name: item.name ?? "",
							data_type: item.dataType ?? "",
						},
					];
				});
			});
			setAPIStatus(true);
		} else {
			message.error(
				"The response of your API is in invalid format or API errors"
			);
		}
	}

	// Retrieve schema field from url and set State
	useEffect(() => {
		initState();
	}, []);

	// Set Initial Values Using State in antd form
	useEffect(() => {
		form.resetFields();
	}, [schema]);

	return (
		<Layout className="layout" style={{ overflow: "auto" }}>
			<div className="navbar" style={{ backgroundColor: "#fff" }}>
				<BackHeader title="Edit the the field" />
			</div>
			<Layout
				className="url_content"
				style={{ backgroundColor: "white" }}
			>
				<Row className="url_row">
					<Col xs={24} xl={8} className="url_col" span={8} />
					<Col xs={24} xl={8} className="url_col" span={8}>
						<Card>
							<Form
								name="fill_url"
								className="url_form"
								onFinish={onFinish}
								form={form}
							>
								<Form.List
									name="schemaList"
									initialValue={schema}
								>
									{(fields) => (
										<div>
											{fields.map((field) => (
												<Row>
													<Col
														xs={24}
														xl={8}
														span={8}
														className="param_col"
													>
														<Form.Item
															{...field}
															name={[
																field.name,
																"name",
															]}
														>
															<Input
																type="text"
																suffix={
																	<Tooltip title="ID Name">
																		<InfoCircleOutlined
																			style={{
																				color: "rgba(0,0,0,.45)",
																			}}
																		/>
																	</Tooltip>
																}
																disabled
															/>
														</Form.Item>
													</Col>
													<Col
														xs={24}
														xl={8}
														span={8}
														className="param_col"
													>
														<Form.Item
															{...field}
															name={[
																field.name,
																"label_name",
															]}
															rules={[
																{
																	required:
																		true,
																	message:
																		"Missing Label Name",
																},
															]}
														>
															<Input
																type="text"
																suffix={
																	<Tooltip title="Label Name">
																		<InfoCircleOutlined
																			style={{
																				color: "rgba(0,0,0,.45)",
																			}}
																		/>
																	</Tooltip>
																}
															/>
														</Form.Item>
													</Col>
													<Col
														xs={24}
														xl={8}
														span={8}
														className="param_col"
													>
														<Form.Item
															{...field}
															name={[
																field.name,
																"data_type",
															]}
															rules={[
																{
																	required:
																		true,
																	message:
																		"Missing data type",
																},
															]}
														>
															<Select
																style={{
																	width: "100%",
																	margin: "0 8px",
																}}
															>
																<Option value="NUMBER">
																	Number
																</Option>
																<Option value="STRING">
																	Text
																</Option>
																<Option value="BOOLEAN">
																	Boolean
																</Option>
															</Select>
														</Form.Item>
													</Col>
												</Row>
											))}
										</div>
									)}
								</Form.List>

								<Form.Item>
									<FormButton
										title="Next"
										isLoading={isLoading}
									/>
								</Form.Item>
							</Form>
						</Card>
					</Col>
					<Col xs={24} xl={8} className="url_col" span={8} />
				</Row>
			</Layout>
		</Layout>
	);
};
